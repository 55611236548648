/* Won't need this once we have latest version of Cogs */
.select {
  width: inherit;
  display: flex;
  justify-content: space-between;
}

.select:hover {
  display: flex;
  background: none;
  box-shadow: none;
}

.styled {
  background: var(--cogs-white);
  border: 1px solid var(--cogs-greyscale-grey4);
}
